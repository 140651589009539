* {
    box-sizing: border-box;
  }
  body {
    background: #bfe0ecee !important;
    min-height: 100vh;
    display: flex;
    font-weight: 400;
    font-family: 'Fira Sans', sans-serif;
  }
  
  body, html, .App, #root, .auth-wrapper {
    width: 100%;
    height: 100%;
  }

 
  