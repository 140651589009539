.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.side-by-side {
  display: flex;
  justify-content: space-between;
}

.eng-length-btn {
  background-color: #0084ff98; 
  color: #000000; 
}

.eng-projection-btn {
  background-color: #fffb0098; 
  color: #000000; 
}

.eng-height-btn {
  background-color: #33ff0098; 
  color: #000000;
}

.eng-pitch-btn {
  background-color: #00b7ff2f;
  color: #000000; 
}

.eng-posts-no-btn {
  background-color: #00ffdd98;
  color: #000000; 
}

.eng-brackets-no-btn {
  background-color: #d400ff98;
  color: #000000; 
}

.eng-roof-sheet-type-dropbox {
  background-color: #ff002298; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;  
}

.eng-post-type-dropbox {
  background-color: #00ff7398; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-gutter-type-dropbox {
  background-color: #ff330098; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-wind-importance-level-dropbox {
  background-color: #008cff98; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-wind-blocking-dropbox {
  background-color: #15ff0098; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-downpipe-type-dropbox  {
  background-color: #0011ff98; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-bracket-type-dropbox {
  background-color: #ffe60098; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-attachment-type-dropbox {
  background-color: #ffae0098; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-attachment-sub-type-dropbox {
  background-color: #008cff98; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-partial-attachment-type-dropbox {
  background-color: #ffee0048; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-fascia-type-dropbox {
  background-color: #00f7ff98; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-fascia-beam-type-dropbox {
  background-color: #f700ff98; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-purlin-beam-type-dropbox {
  background-color: #48ff0098; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-rafter-beam-type-dropbox {
  background-color: #ffd00098; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-footing-type-dropbox {
  background-color: #00ff8098; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

.eng-attachment-top-type-dropbox {
  background-color: #4b185598; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}


.eng-attachment-bottom-type-dropbox {
  background-color: #8aad08dc; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}


.eng-attachment-right-type-dropbox {
  background-color: #ce1d1d98; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}


.eng-attachment-left-type-dropbox {
  background-color: #a7734298; 
  color: #000000; 
  height: 32px;
  border-radius: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
